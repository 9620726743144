import $ from "jquery";

/**
 * Simple error handler that will attach CSS class on the DOM element in case when unhandled JavaScript error occurs.
 */
export default class JavaScriptErrorHandler {

  constructor(loggingUrl) {
    this.loggingUrl = loggingUrl;
    this.pageErrorWarningElementId = "page-error-warning";
  }

  bind() {
    window.onerror = (message, sourceUrl, lineNumber, columnNumber, error) => {
      this.showErrorBadge();
      this.logErrorToLogFile(message, sourceUrl, lineNumber, columnNumber, error);
      return false;
    };
  }

  showErrorBadge() {
    $(document.getElementById(this.pageErrorWarningElementId)).show();
  }

  logErrorToLogFile(message, sourceUrl, lineNumber, columnNumber, error) {
    $.ajax({
      url: this.loggingUrl,
      method: "POST",
      data: {
        message: message,
        sourceUrl: sourceUrl,
        documentUrl: document.location.toString(),
        lineNumber: lineNumber,
        columnNumber: columnNumber,
        stack: error.stack
      }
    });
  }

}


