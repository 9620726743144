import $ from "jquery";

export default class CollapsibleBreadcrumb {

  constructor(settings) {
    this.$breadcrumbContainer = $(settings.breadcrumbsContainerSelector);
    this.$menuContainer = $(settings.menuContainerSelector);
    this.$breadcrumbItems = this.$breadcrumbContainer
      .find("ol")
      .find("li");
    this.configuation = {
      MENU_CONTAINER_OFFSET: 30,
      HIDDEN_CLASS: "hidden",
      NAVBAR_TOGGLE_CLASS: ".navbar-toggle--ysoft",
      BREADCRUMBS_CLASS: ".breadcrumb",
    };
  }

  init() {
    if (this.$breadcrumbContainer.length > 0) {
      $(window).resize(() => this.refresh());
      this.refresh();
    }
  }

  refresh() {
    this.setMaxContainerWidth();
    this.setMenuItemsVisibility();
  }

  setMaxContainerWidth() {
    const containerWidth = this.$breadcrumbContainer.parent().width();
    this.$breadcrumbContainer.outerWidth(Math.floor(containerWidth - this.getMenuWidth()));
  }

  getMenuWidth() {
    let menuWidth = 0;
    if (this.$menuContainer.is(":visible") && !this.isMobileMode()) {
      menuWidth = this.$menuContainer.outerWidth() + this.configuation.MENU_CONTAINER_OFFSET;
    }
    return menuWidth;
  }

  isMobileMode() {
    return this.$breadcrumbContainer.children(this.configuation.NAVBAR_TOGGLE_CLASS).is(':visible');
  }

  setMenuItemsVisibility() {
    if (this.shouldHideMenuItem()) {
      this.hideAllPossibleMenuItems();
    } else {
      this.showAllPossibleMenuItems();
    }
  }

  hideAllPossibleMenuItems() {
    const itemsSize = this.$breadcrumbItems.length - 1;
    for (let i = 0; i < itemsSize; i += 1) {
      if (this.shouldHideMenuItem()) {
        this.hideMenuItem(i);
      } else {
        break;
      }
    }
  }

  showAllPossibleMenuItems() {
    const itemsSize = this.$breadcrumbItems.length - 1;
    for (let i = itemsSize; i >= 0; i -= 1) {
      if (!this.shouldHideMenuItem() && !this.showMenuItemIfCan(i)) {
        break;
      }
    }
  }

  shouldHideMenuItem() {
    const container = this.$breadcrumbContainer[0];
    return container.offsetWidth < container.scrollWidth;
  }

  hideMenuItem(index) {
    this.$breadcrumbItems.eq(index).toggleClass(this.configuation.HIDDEN_CLASS, true);
  }

  showMenuItem(index) {
    this.$breadcrumbItems.eq(index).toggleClass(this.configuation.HIDDEN_CLASS, false);
  }

  showMenuItemIfCan(index) {
    let result = true;
    this.showMenuItem(index);
    if (this.shouldHideMenuItem()) {
      this.hideMenuItem(index);
      result = false;
    }
    return result;
  }

}
